import { Component, OnInit } from '@angular/core';
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'Sell Music Online / SMO-Links';
  constructor(private metaService: Meta) {}

  ngOnInit() {
    //this.titleService.setTitle(this.title);
    this.metaService.addTags([
      {name: 'keywords', content: 'Sell Music Online, SMO-kin, SMO-Links, Indie Artists, SMO Artists'},
      {name: 'description', content: 'SMO-Links designed for Artis for easy Sharing & Marketing.'},
      {name: 'robots', content: 'index, follow'}
    ]);
  }
}

