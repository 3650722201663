import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  { path: '', loadChildren: './home/home.module#HomeModule' },
  { path: 'uhmm', loadChildren: './uhmm/uhmm.module#UhmmModule' },
  { path: 'sos', loadChildren: './sos/sos.module#SosModule' },
  { path: 'tanooj-mehra', loadChildren: './tanooj-mehra/tanooj-mehra.module#TanoojMehraModule' },
  { path: 'musafr', loadChildren: './musafr/musafr.module#MusafrModule' },
  { path: 'gravity', loadChildren: './gravity/gravity.module#GravityModule' },
  { path: 'danu-t-chandrakumar', loadChildren: './danu-t-chandrakumar/danu-t-chandrakumar.module#DanuTChandrakumarModule' },
  { path: 'devansh-tripathi', loadChildren: './devansh-tripathi/devansh-tripathi.module#DevanshTripathiModule' },
  { path: 'divy-lata', loadChildren: './divy-lata/divy-lata.module#DivyLataModule' },
  { path: 'wrizzwizz', loadChildren: './wrizzwizz/wrizzwizz.module#WrizzWizzModule' },
  { path: 'raaj-saluja', loadChildren: './raaj-saluja/raaj-saluja.module#RaajSalujaModule' },
  { path: 'raagafellas', loadChildren: './raagafellas/raagafellas.module#RaagafellasModule' },
  { path: 'vrindam', loadChildren: './vrindam/vrindam.module#VrindamModule' },
  { path: 'felix-and-the-cat', loadChildren: './felix-and-the-cat/felix-and-the-cat.module#FelixAndTheCatModule' },
  { path: 'shotgun-shaggy', loadChildren: './shotgun-shaggy/shotgun-shaggy.module#ShotgunShaggyModule' },
  { path: 'aishwarya-anand', loadChildren: './aishwarya-anand/aishwarya-anand.module#AishwaryaAnandModule' },
  { path: 'the-winchy', loadChildren: './the-winchy/the-winchy.module#TheWinchyModule' },
  { path: 'gaurav-dagaonkar', loadChildren: './gaurav-dagaonkar-/gaurav-dagaonkar-.module#GauravDagaonkarModule' },
  { path: 'rebelwxrld', loadChildren: './rebelwxrld/rebelwxrld.module#RebelwxrldModule' },
  { path: 'shudhi-ramani', loadChildren: './shudhi-ramani/shudhi-ramani.module#ShudhiRamaniModule' },
  { path: 'jatin-khurana', loadChildren: './jatin-khurana/jatin-khurana.module#JatinKhuranaModule' },
  { path: 'keshuv-huria', loadChildren: './keshuv-huria/keshuv-huria.module#KeshuvHuriaModule' },
  { path: 'sumeet-adhirath', loadChildren: './sumeeth-and-adhirath/sumeeth-and-adhirath.module#SumeethAndAdhirathModule' },
  { path: 'stonned-generation', loadChildren: './stonned-generation/stonned-generation.module#StonnedGenerationModule' },
  { path: 'shipra-malhotra', loadChildren: './shipra-malhotra/shipra-malhotra.module#ShipraMalhotraModule' },
  { path: 'anirudh-mrinal', loadChildren: './anirudh-mrinal/anirudh-mrinal.module#AnirudhMrinalModule' },
  { path: 'heisengarg', loadChildren: './heisengarg/heisengarg.module#HeisengargModule' },
  { path: 'vibha-kale', loadChildren: './vibha-kale/vibha-kale.module#VibhaKaleModule' },
  { path: 'pb', loadChildren: './pb/pb.module#PbModule' },
  { path: 'xarons', loadChildren: './xarons/xarons.module#XaronsModule' },
  { path: 'prithvi-shetty', loadChildren: './prithvi-shetty-ohno-dyb/prithvi-shetty-ohno-dyb.module#PrithviShettyOhnoDybModule' },
  { path: 'pt-deepak-kshirsagar', loadChildren: './pt-deepak-kshirsagar/pt-deepak-kshirsagar.module#PtDeepakKshirsagarModule' },
  { path: 'moumita-banerjee', loadChildren: './moumita-banerjee/moumita-banerjee.module#MoumitaBanerjeeModule' },
  { path: 'insignia', loadChildren: './insignia/insignia.module#InsigniaModule' },
  { path: 'gaurav-sarda', loadChildren: './gaurav-sarda/gaurav-sarda.module#GauravSardaModule' },
  { path: 'christmas', loadChildren: './christmas/christmas.module#ChristmasModule' },
  { path: 'nitin-aishwarya', loadChildren: './nitin-aishwarya/nitin-aishwarya.module#NitinAishwaryaModule' },
  { path: 'sifar-feat-suzane', loadChildren: './sifar-feat-suzane/sifar-feat-suzane.module#SifarFeatSuzaneModule' },
  { path: 'sns-rappers', loadChildren: './bizzleft-noby-sammy-guru-ji/bizzleft-noby-sammy-guru-ji.module#BizzleftNobySammyGuruJiModule' },
  { path: 'nbh', loadChildren: './nowhere-but-here/nowhere-but-here.module#NowhereButHereModule' },
  { path: 'ishaan-nigam', loadChildren: './ishaan-nigam/ishaan-nigam.module#IshaanNigamModule' },
  { path: 'bharat-leppard', loadChildren: './bharat-leppard/bharat-leppard.module#BharatLeppardModule' },
  { path: 'bta', loadChildren: './bombay-the-artist/bombay-the-artist.module#BombayTheArtistModule' },
  { path: 'divyesh-rajpal', loadChildren: './divyesh-rajpal/divyesh-rajpal.module#DivyeshRajpalModule' },
  { path: 'ab', loadChildren: './ab/ab.module#ABModule' },
  { path: 'shivam-verma', loadChildren: './shivam-verma/shivam-verma.module#ShivamVermaModule' },
  { path: 'ysoblue', loadChildren: './ysoblue/ysoblue.module#YsoblueModule' },
  { path: 'nath', loadChildren: './nath/nath.module#NathModule' },
  { path: 'teesta-record', loadChildren: './kandarpa-roy/kandarpa-roy.module#KandarpaRoyModule' },
  { path: 'anamika-sharma', loadChildren: './anamika-sharma/anamika-sharma.module#AnamikaSharmaModule' },
  { path: 'oblitera', loadChildren: './oblitera/oblitera.module#ObliteraModule' },
  { path: 'jashan-bhunkar', loadChildren: './jashan-bhunkar/jashan-bhunkar.module#JashanBhunkarModule' },
  { path: 'shuchi-arora', loadChildren: './shuchi-arora/shuchi-arora.module#ShuchiAroraModule' },
  { path: 'vinod-krishnan', loadChildren: './vinod-krishnan/vinod-krishnan.module#VinodKrishnanModule' },
  { path: 'tzejax-suhasini', loadChildren: './tzejax-suhasini/tzejax-suhasini.module#TzejaxSuhasiniModule' },
  { path: 'katoptris', loadChildren: './katoptris/katoptris.module#KatoptrisModule' },
  { path: 'karan-rana', loadChildren: './karan-rana/karan-rana.module#KaranRanaModule' },
  { path: 'aryadox', loadChildren: './aryadox/aryadox.module#AryadoxModule' },
  { path: 'skandh', loadChildren: './skandh/skandh.module#SkandhModule' },
  { path: 'starkkk', loadChildren: './starkkk/starkkk.module#StarkkkModule' },
  { path: 'bhuvan-malik', loadChildren: './bhuvan-malik/bhuvan-malik.module#BhuvanMalikModule' },
  { path: 'pakhandi', loadChildren: './pakhandi/pakhandi.module#PakhandiModule' },
  { path: 'pvrpwine', loadChildren: './pvrpwine/pvrpwine.module#PvrpwineModule' },
  { path: 'abhishek-kate', loadChildren: './abhishek-kate/abhishek-kate.module#AbhishekKateModule' },
  { path: 'ranvir-singh', loadChildren: './ranvir-singh/ranvir-singh.module#RanvirSinghModule' },
  { path: 'rhyfil', loadChildren: './rhyfil/rhyfil.module#RhyfilModule' },
  { path: 'dr3amer', loadChildren: './dr3amer/dr3amer.module#Dr3amerModule' },
  { path: 'akib', loadChildren: './akib/akib.module#AkibModule' },
  { path: 'teesta-record', loadChildren: './hirok-roy/hirok-roy.module#HirokRoyModule' },
  { path: 'bengal-tunez', loadChildren: './hori-lal/hori-lal.module#HoriLalModule' },
  { path: 'sumann', loadChildren: './sumann/sumann.module#SumannModule' },
  { path: 'do', loadChildren: './do/do.module#DoModule' },
  { path: 'arndm', loadChildren: './arndm/arndm.module#ArndmModule' },
  { path: 'aaab', loadChildren: './aaab/aaab.module#AaabModule' },
  { path: 'rajalakshmee-sanjay', loadChildren: './rajalakshmee-sanjay/rajalakshmee-sanjay.module#RajalakshmeeSanjayModule' },
  { path: 'prashant-the-rapper', loadChildren: './prashant-the-rapper/prashant-the-rapper.module#PrashantTheRapperModule' },
  { path: 'bhoomi-trivedi', loadChildren: './bhoomi-trivedi/bhoomi-trivedi.module#BhoomiTrivediModule' },
  { path: 'parul-university', loadChildren: './parul-university/parul-university.module#ParulUniversityModule' },
  { path: 'gurmaan-singh-thakkar', loadChildren: './gurmaan-singh-thakkar/gurmaan-singh-thakkar.module#GurmaanSinghThakkarModule' },
  { path: 'mannu-badosh', loadChildren: './mannu-badosh/mannu-badosh.module#MannuBadoshModule' },
  { path: 'ipsingh', loadChildren: './ipsingh/ipsingh.module#IpsinghModule' },
  { path: 'yashwardhan', loadChildren: './yashwardhan/yashwardhan.module#YashwardhanModule' },
  { path: 'bharat-jhangiani', loadChildren: './bharat-jhangiani/bharat-jhangiani.module#BharatJhangianiModule' },
  { path: 'arun-singhal', loadChildren: './arun-singhal/arun-singhal.module#ArunSinghalModule' },
  { path: 'bebhumika-kalla-sha', loadChildren: './bebhumika-kalla-sha/bebhumika-kalla-sha.module#BebhumikaKallaShaModule' },
  { path: 'nilesh-patel', loadChildren: './nilesh-patel/nilesh-patel.module#NileshPatelModule' },
  { path: 'shikhar', loadChildren: './shikhar/shikhar.module#ShikharModule' },
  { path: 'how-about', loadChildren: './how-about/how-about.module#HowAboutModule' },
  { path: 'swapnil-ganvir', loadChildren: './swapnil-ganvir/swapnil-ganvir.module#SwapnilGanvirModule' },
  { path: 'devesh-bhardwaj', loadChildren: './devesh-bhardwaj/devesh-bhardwaj.module#DeveshBhardwajModule' },
  { path: 'satender-verma', loadChildren: './satender-verma/satender-verma.module#SatenderVermaModule' },
  { path: 'mohit-lalwani', loadChildren: './mohit-lalwani/mohit-lalwani.module#MohitLalwaniModule' },
  { path: 'harshita-sabharwal', loadChildren: './harshita-sabharwal/harshita-sabharwal.module#HarshitaSabharwalModule' },
  { path: 'sarsenapati-hambirrao', loadChildren: './sarsenapati-hambirrao/sarsenapati-hambirrao.module#SarsenapatiHambirraoModule' },
  { path: 'divesh-bhardwaj', loadChildren: './divesh-bhardwaj/divesh-bhardwaj.module#DiveshBhardwajModule' },
  { path: 'gauri-singh', loadChildren: './jyoti-singh/jyoti-singh.module#JyotiSinghModule' },
  { path: 'shalof-khan', loadChildren: './shalof-khan/shalof-khan.module#ShalofKhanModule' },
  { path: 'yousuf-tareen', loadChildren: './yousuf-tareen/yousuf-tareen.module#YousufTareenModule' },
  { path: 'akarsh-sahay', loadChildren: './akarsh-sahay/akarsh-sahay.module#AkarshSahayModule' },
  { path: 'rishangye', loadChildren: './rishangye-dashaansh-farukh/rishangye-dashaansh-farukh.module#RishangyeDashaanshFarukhModule' },
  { path: 'joy-e-bike', loadChildren: './joy-e-bike/joy-e-bike.module#JoyEBikeModule' },
  { path: 'manik-sethi', loadChildren: './manik-sethi/manik-sethi.module#ManikSethiModule' },
  { path: 'sreekesh-menon', loadChildren: './sreekesh-menon/sreekesh-menon.module#SreekeshMenonModule' },
  { path: 'joy-goswami', loadChildren: './joy-goswami/joy-goswami.module#JoyGoswamiModule' },
  { path: 'roopesh-induchoodan', loadChildren: './roopesh-induchoodan/roopesh-induchoodan.module#RoopeshInduchoodanModule' },
  { path: 'siddarth-soni', loadChildren: './siddarth-soni/siddarth-soni.module#SiddarthSoniModule' },
  { path: 'sippy', loadChildren: './sippy/sippy.module#SippyModule' },
  { path: 'ishan-tyagi', loadChildren: './ishan-tyagi/ishan-tyagi.module#IshanTyagiModule' },
  { path: 'jaya-lalwani', loadChildren: './jaya-lalwani/jaya-lalwani.module#JayaLalwaniModule' },
  { path: 'mr-brijesh-chaturvedi', loadChildren: './mr-brijesh-chaturvedi/mr-brijesh-chaturvedi.module#MrBrijeshChaturvediModule' },
  { path: 'sweta-pakhale-morankar', loadChildren: './sweta-pakhale-morankar/sweta-pakhale-morankar.module#SwetaPakhaleMorankarModule' },
  { path: 'silvercheel', loadChildren: './silvercheel/silvercheel.module#SilvercheelModule' },
  { path: 'jaw', loadChildren: './jaw/jaw.module#JawModule' },
  { path: 'lil-sidley', loadChildren: './lil-sidley/lil-sidley.module#LilSidleyModule' },
  { path: 'mohit-rajput', loadChildren: './mohit-rajput/mohit-rajput.module#MohitRajputModule' },
  { path: 'kaush', loadChildren: './kaush/kaush.module#KaushModule' },
  { path: 'siddhant-adake', loadChildren: './siddhant-adake/siddhant-adake.module#SiddhantAdakeModule' },
  { path: 'anuka-mitra', loadChildren: './anuka-mitra/anuka-mitra.module#AnukaMitraModule' },
  { path: 'creazy-star', loadChildren: './creazy-star/creazy-star.module#CreazyStarModule' },
  { path: 'vsop', loadChildren: './vsop/vsop.module#VsopModule' },
  { path: 'maryjane-mob', loadChildren: './maryjane-mob/maryjane-mob.module#MaryjaneMobModule' },
  { path: 'starbxy', loadChildren: './starbxy/starbxy.module#StarbxyModule' },
  { path: 'illegal', loadChildren: './illegal/illegal.module#IllegalModule' },
  { path: 'ojas', loadChildren: './ojas/ojas.module#OjasModule' },
  { path: 'ghulam-hasan-khan', loadChildren: './ghulam-hasan-khan/ghulam-hasan-khan.module#GhulamHasanKhanModule' },
  { path: 'bairagi', loadChildren: './bairagi/bairagi.module#BairagiModule' },
  { path: 'mana-hyderabadi-folk', loadChildren: './mana-hyderabadi-folk/mana-hyderabadi-folk.module#ManaHyderabadiFolkModule' },
  { path: 'zigzag-the-musicwala', loadChildren: './zigzag-the-musicwala/zigzag-the-musicwala.module#ZigzagTheMusicwalaModule' },
  { path: 'lala', loadChildren: './lala/lala.module#LalaModule' },
  { path: 'mohit-pandey', loadChildren: './mohit-pandey/mohit-pandey.module#MohitPandeyModule' },
  { path: 'shubham-kulkarni', loadChildren: './shubham-kulkarni/shubham-kulkarni.module#ShubhamKulkarniModule' },
  { path: 'ordsmith', loadChildren: './ordsmith/ordsmith.module#OrdsmithModule' },
  { path: 'abu-saalim', loadChildren: './abu-saalim/abu-saalim.module#AbuSaalimModule' },
  { path: 'sanya', loadChildren: './sanya/sanya.module#SanyaModule' },
  { path: 'abiraa', loadChildren: './abiraa/abiraa.module#AbiraaModule' },
  { path: 'krushna-jadhav', loadChildren: './krushna-jadhav/krushna-jadhav.module#KrushnaJadhavModule' },
  { path: 'musical-satire', loadChildren: './musical-satire/musical-satire.module#MusicalSatireModule' },
  { path: 'natascha', loadChildren: './natascha/natascha.module#NataschaModule' },
  { path: 'harshit-chauhan', loadChildren: './harshit-chauhan/harshit-chauhan.module#HarshitChauhanModule' },
  { path: 'limitless', loadChildren: './limitless/limitless.module#LimitlessModule' },
  { path: 'ruchir', loadChildren: './ruchir/ruchir.module#RuchirModule' },
  { path: 'balaji-gopinath', loadChildren: './balaji-gopinath/balaji-gopinath.module#BalajiGopinathModule' },
  { path: 'susmita-narayan', loadChildren: './susmita-narayan/susmita-narayan.module#SusmitaNarayanModule' },
  { path: 'prshant-shah', loadChildren: './prshant-shah/prshant-shah.module#PrshantShahModule' },
  { path: 'jonita-gandhi', loadChildren: './jonita-gandhi/jonita-gandhi.module#JonitaGandhiModule' },
  { path: 'vincent-boral', loadChildren: './vincent-boral/vincent-boral.module#VincentBoralModule' },
  { path: 'praveen-kaur', loadChildren: './praveen-kaur/praveen-kaur.module#PraveenKaurModule' },
  { path: 'sunil-chauhan', loadChildren: './sunil-chauhan/sunil-chauhan.module#SunilChauhanModule' },
  { path: 'manraj-veer', loadChildren: './manraj-veer/manraj-veer.module#ManrajVeerModule' },
  { path: 'vivek-jain', loadChildren: './vivek-jain/vivek-jain.module#VivekJainModule' },
  { path: 'ankur-jain-ank', loadChildren: './ankur-jain-ank/ankur-jain-ank.module#AnkurJainAnkModule' },
  { path: 'niloy-roy', loadChildren: './niloy-roy/niloy-roy.module#NiloyRoyModule' },
  { path: 'omkar-raj', loadChildren: './omkar-raj/omkar-raj.module#OmkarRajModule' },
  { path: 'babli-thakur', loadChildren: './babli-thakur/babli-thakur.module#BabliThakurModule' },
  { path: 'mukta-bhardwaj', loadChildren: './mukta-bhardwaj/mukta-bhardwaj.module#MuktaBhardwajModule' },
  { path: 'drastic', loadChildren: './drastic/drastic.module#DrasticModule' },
  { path: 'shlok-chiplunkar', loadChildren: './shlok-chiplunkar/shlok-chiplunkar.module#ShlokChiplunkarModule' },
  { path: 'shaz-ali', loadChildren: './shaz-ali/shaz-ali.module#ShazAliModule' },
  { path: 'ambarish-nag', loadChildren: './ambarish-nag/ambarish-nag.module#AmbarishNagModule' },
  { path: 'jaw-starbxy', loadChildren: './jaw-starbxy/jaw-starbxy.module#JawStarbxyModule' },
  { path: 'mc-trip', loadChildren: './mc-trip/mc-trip.module#McTripModule' },
  { path: 'kapil-chawla', loadChildren: './kapil-chawla/kapil-chawla.module#KapilChawlaModule' },
  { path: 'mxrzi', loadChildren: './mxrzi/mxrzi.module#MXRZIModule' },
  { path: 'jashan-bhumkar', loadChildren: './jashan-bhumkar/jashan-bhumkar.module#JashanBhumkarModule' },
  { path: 'rahul-b-seth', loadChildren: './rahul-b-seth/rahul-b-seth.module#RahulBSethModule' },
  { path: 'keshav-d-artistx', loadChildren: './keshav-d-artistx/keshav-d-artistx.module#KeshavDArtistxModule' },
  { path: 'mohit-kumarm', loadChildren: './mohit-kumarm/mohit-kumarm.module#MohitKumarmModule' },
  { path: 'punam-samyal', loadChildren: './punam-samyal/punam-samyal.module#PunamSamyalModule' },
  { path: 'takhache-figmenttt-starbxy', loadChildren: './takhache-figmenttt-starbxy/takhache-figmenttt-starbxy.module#TakhacheFigmentttStarbxyModule' },
  { path: 'takhache', loadChildren: './takhache/takhache.module#TakhacheModule' },
  { path: 'strick9', loadChildren: './strick9/strick9.module#Strick9Module' },
  { path: 'divya-kumar', loadChildren: './divya-kumar/divya-kumar.module#DivyaKumarModule' },
  { path: 'shubham-gaur', loadChildren: './shubham-gaur/shubham-gaur.module#ShubhamGaurModule' },
  { path: 'yayati', loadChildren: './yayati/yayati.module#YayatiModule' },
  { path: 'abhinandan-chaurasiya', loadChildren: './abhinandan-chaurasiya/abhinandan-chaurasiya.module#AbhinandanChaurasiyaModule' },
  { path: 'rc-magnum', loadChildren: './rc-magnum/rc-magnum.module#RcMagnumModule' },
  { path: 'aryan', loadChildren: './aryan/aryan.module#AryanModule' },
  { path: 'mayank-thakur', loadChildren: './mayank-thakur/mayank-thakur.module#MayankThakurModule' },
  { path: 'ejaz-rahman', loadChildren: './ejaz-rahman/ejaz-rahman.module#EjazRahmanModule' },
  { path: 'takhache-starbxy', loadChildren: './takhache-starbxy/takhache-starbxy.module#TakhacheStarbxyModule' },
  { path: 'aparajita-chakraborty', loadChildren: './aparajita-chakraborty/aparajita-chakraborty.module#AparajitaChakrabortyModule' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }